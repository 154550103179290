import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import React, { FC } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#6e777a',
        },
    },
    transitions: { create: () => 'none' },
});

const LoadingSkeletonThemeProvider: React.FC<{ children: React.ReactNode, theme: Theme }> = ({ children }) => {
    return (
        <SkeletonTheme
            baseColor={'#eceeef'}
            highlightColor={'#e3e6e7'}>
            {children}
        </SkeletonTheme>
    );
};

interface IMaterialUiProviderProps {
    children?: React.ReactNode;
}
export const MaterialUiProvider: FC<IMaterialUiProviderProps> = ({ children }) => {
    return <ThemeProvider theme={theme}>
        <LoadingSkeletonThemeProvider theme={theme}>
            {children}
        </LoadingSkeletonThemeProvider>
    </ThemeProvider>;
};
