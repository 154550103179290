import { SearchDuplicatesRequest, SearchThirdsRequest } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_THIRDS_FILTERS: SearchThirdsRequest = {
    skip: 0,
    take: 15,
    onlyActive: true,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_THIRDS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: SearchThirdsRequest,
        total: number,
    },
    thirds: {} as { readonly [thirdId: number]: ApiRequest },
    searchDuplicates: {
        filters: {},
        isFetching: false,
        didInvalidate: true,
        ignoreDuplicate: false,
    } as ApiRequest<number[]> & {
        filters: Partial<SearchDuplicatesRequest>,
        ignoreDuplicate: boolean,
    },
    creationOrEdition: {} as { readonly [icId: string]: boolean },
    deactivate: {} as { readonly [icId: string]: boolean },
    refreshMarketData: {} as { readonly [thirdId: string]: { readonly [brandId: string]: boolean } },
} as const;

export type ThirdsState = typeof initialState;

export const ThirdsReducer: Reducer<ThirdsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@THIRDS/SET_SEARCH_THIRDS_SEARCH_TERMS':
            if (state.search.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/SEARCH_THIRDS_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip && state.search.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/SET_SEARCH_THIRDS_ONLY_ACTIVE':
            if (state.search.filters.onlyActive === action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        onlyActive: action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/RESET_SEARCH_THIRDS':
            const isAlreadyDefaultFilter = Object.entries(state.search.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'onlyActive':
                        return filterValue === DEFAULT_SEARCH_THIRDS_FILTERS.onlyActive;
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_THIRDS_FILTERS.take;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...DEFAULT_SEARCH_THIRDS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/SEARCH_THIRDS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@THIRDS/SEARCH_THIRDS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.thirdIds,
                    total: action.payload.total,
                },
            };

        case '@THIRDS/SEARCH_THIRDS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/FETCH_THIRDS':
            return {
                ...state,
                thirds: createFetchThirdsState(
                    state,
                    action.payload.thirdIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@THIRDS/FETCH_THIRDS_SUCCESS':
            return {
                ...state,
                thirds: createFetchThirdsState(
                    state,
                    action.payload.thirdIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@THIRDS/FETCH_THIRDS_FAILURE':
            return {
                ...state,
                thirds: createFetchThirdsState(
                    state,
                    action.payload.thirdIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@THIRDS/SEARCH_DUPLICATES_SET_NAME':
            if (state.searchDuplicates.filters.name?.toLowerCase() === action.payload.name?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    filters: {
                        ...state.searchDuplicates.filters,
                        name: action.payload.name,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    data: undefined,
                },
            };

        case '@THIRDS/SEARCH_DUPLICATES_SET_COUNTRY':
            if (state.searchDuplicates.filters.countryId === action.payload.countryId) {
                return state;
            }
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    filters: {
                        ...state.searchDuplicates.filters,
                        countryId: action.payload.countryId,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    data: undefined,
                },
            };

        case '@THIRDS/SEARCH_DUPLICATES_SET_RIC':
            if (state.searchDuplicates.filters.ric?.toLowerCase() === action.payload.ric?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    filters: {
                        ...state.searchDuplicates.filters,
                        ric: action.payload.ric,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    data: undefined,
                },
            };

        case '@THIRDS/SEARCH_DUPLICATES_SET_IGNORE':
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    ignoreDuplicate: action.payload.ignoreDuplicate,
                },
            };

        case '@THIRDS/RESET_SEARCH_DUPLICATES':
            const isAlreadyDefaultDuplicateFilter = !state.searchDuplicates.filters.name
                && !state.searchDuplicates.filters.countryId
                && !state.searchDuplicates.filters.ric;
            if (isAlreadyDefaultDuplicateFilter) {
                return state;
            }

            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    filters: {},
                    didInvalidate: true,
                    isFetching: false,
                    data: undefined,
                },
            };

        case '@THIRDS/SEARCH_DUPLICATES':
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@THIRDS/SEARCH_DUPLICATES_SUCCESS':
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.thirdIds,
                },
            };

        case '@THIRDS/SEARCH_DUPLICATES_FAILURE':
            return {
                ...state,
                searchDuplicates: {
                    ...state.searchDuplicates,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/CREATE_THIRDS':
            return {
                ...state,
                creationOrEdition: {
                    ...state.creationOrEdition,
                    [action.payload.ric]: true,
                },
            };

        case '@THIRDS/CREATE_THIRDS_SUCCESS':
            return {
                ...state,
                creationOrEdition: {
                    ...state.creationOrEdition,
                    [action.payload.ric]: false,
                },
                thirds: {
                    ...state.thirds,
                    [action.payload.thirdId]: {
                        ...state.thirds[action.payload.thirdId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@THIRDS/CREATE_THIRDS_FAILURE':
            return {
                ...state,
                creationOrEdition: {
                    ...state.creationOrEdition,
                    [action.payload.ric]: false,
                },
            };

        case '@THIRDS/DEACTIVATE_THIRDS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.thirdId]: true,
                },
            };

        case '@THIRDS/DEACTIVATE_THIRDS_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.thirdId]: false,
                },
                thirds: createFetchThirdsState(
                    state,
                    [action.payload.thirdId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@THIRDS/DEACTIVATE_THIRDS_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.thirdId]: false,
                },
            };

        case '@THIRDS/REFRESH_THIRD_MARKET_DATA':
            return {
                ...state,
                refreshMarketData: {
                    ...state.refreshMarketData,
                    [action.payload.thirdId]: {
                        ...state.refreshMarketData?.[action.payload.thirdId],
                        [action.payload.brand]: true,
                    },
                },
            };

        case '@THIRDS/REFRESH_THIRD_MARKET_DATA_SUCCESS':
            return {
                ...state,
                refreshMarketData: {
                    ...state.refreshMarketData,
                    [action.payload.thirdId]: {
                        ...state.refreshMarketData?.[action.payload.thirdId],
                        [action.payload.brand]: false,
                    },
                },
            };

        case '@THIRDS/REFRESH_THIRD_MARKET_DATA_FAILURE':
            return {
                ...state,
                refreshMarketData: {
                    ...state.refreshMarketData,
                    [action.payload.thirdId]: {
                        ...state.refreshMarketData?.[action.payload.thirdId],
                        [action.payload.brand]: false,
                    },
                },
            };

        default:
            return state;
    }
};

const createFetchThirdsState = (state: ThirdsState, thirdIds: number[], apiRequest: ApiRequest) => {
    let stateThirds = {
        ...state.thirds,
    };

    for (const thirdId of thirdIds) {
        stateThirds = {
            ...stateThirds,
            [thirdId]: {
                ...state.thirds[thirdId],
                ...apiRequest,
            },
        };
    }
    return stateThirds;
};
